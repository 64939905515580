import {CREATE, DELETE, GET_LIST, GET_MANY, GET_ONE, UPDATE} from 'react-admin'
import {ImageParse} from './ImageMethod'
import {convertImagePath, uploadToS3} from './ImageHandler'

export async function request(type, params) {
  let url = '/admin/v3/offerwalls'
  const options = {}

  switch (type) {
    case UPDATE:
    case CREATE:
      if (type === UPDATE) {
        url += `/${params.id}`
        options.method = 'PUT'
      } else options.method = 'POST'

      options.data = params.data
      const data = {...params.data}

      if (options.data.titleImagePath.image) {
        if (options.data.titleImagePath.image.substring(0, 5) === 'https') {
          options.data.titleImagePath.image = ImageParse(options.data.titleImagePath.image, 'offerwalls')
        }
      }
      options.data = await uploadToS3(data, 'titleImagePath', 'offerwalls', 'titleImagePath')

      const src = options.data.titleImagePath.image
      options.data.titleImagePath = src

      if (options.data.titleImagePath.substring(0, 5) === 'https') {
        options.data.titleImagePath = ImageParse(options.data.titleImagePath, 'offerwalls')
      }
      break

    case GET_LIST:
      const {page, perPage} = params.pagination
      const {field, order} = params.sort
      options.params = {
        ...params.filter,
        sort: field,
        order,
        start: (page - 1) * perPage,
        perPage
      }
      break

    case GET_MANY:
      if (params.ids.length === 1) url += `/${params.ids[0]}`
      break

    case GET_ONE:
      url += `/${params.id}`
      const offerwallId = params.id
      break

    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break

    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res

  switch (type) {
    case UPDATE:
      ret = params
      break

    case GET_MANY:
      if (params.ids.length === 1) ret = {data: [data]}
      else ret = {data}
      break

    case GET_ONE:
      data.titleImagePath = convertImagePath(data.titleImagePath, 'large')
      if (typeof data.titleImagePath === 'string') {
        const image = data.titleImagePath
        data.titleImagePath = {}
        data.titleImagePath.image = image
      }
      ret = {data}
      break

    case GET_LIST:
      data.data.forEach((row) => {
        if (row.titleImagePath !== '') {
          row.titleImagePath = convertImagePath(row.titleImagePath, 'large')
        }
      })
      ret = data
      break

    case DELETE:
      ret = {data: params}
      break

    default:
      if (data) ret = {data}
      break
  }
  return ret
}
