import {DELETE, GET_LIST, GET_MANY, GET_ONE, UPDATE} from 'react-admin'
import {convertImagePath} from './ImageHandler'

let recentOptions

export function request(type, params) {
  let url = '/admin/talks'
  let options = {}

  switch (type) {
    case GET_LIST:
      const {page, perPage} = params.pagination
      const {field, order} = params.sort

      if (!(params.filter.hasOwnProperty('startDate') && params.filter.hasOwnProperty('endDate'))) {
        if (params.filter.hasOwnProperty('startDate')) delete params.filter.startDate
        if (params.filter.hasOwnProperty('endDate')) delete params.filter.endDate
      }

      options.params = {
        ...params.filter,
        sort: field,
        order,
        start: (page - 1) * perPage,
        perPage
      }
      recentOptions = options
      break

    case GET_MANY:
      if (params.ids.length === 1) url += `/${params.ids[0]}`
      break

    case GET_ONE:
      url += `/${params.id}`
      if (params.hasOwnProperty('query')) options.params = {...params.query}
      break

    case UPDATE:
      url += `/${params.id}`
      options.method = 'PUT'
      options.data = params.data
      break

    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break

    case 'COMMENT':
      url += `/${params.id}/comments`
      options.method = 'POST'
      options.data = params.data
      break

    case 'EXCEL':
      options = recentOptions
      options.params.perPage = undefined
      break

    case 'EXCEL30D':
      options.params.start = 0
      break

    default:
      break
  }

  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res

  switch (type) {
    case UPDATE:
      ret = params
      break

    case GET_MANY:
      if (params.ids.length === 1) ret = {data: [data]}
      else ret = data
      break

    case GET_LIST:
      const handleData = data.data.map((talk) => {
        if (talk.images.length > 0) {
          talk.thumbnailImage = convertImagePath(talk.images[0].thumbnail)
        }
        return talk
      })
      ret = {data: handleData, total: data.total}
      break

    case GET_ONE:
      data.images = data.images.map((image) => {
        return {
          image: convertImagePath(image.image, 'large')
        }
      })

      if (data.hasOwnProperty('comments')) {
        data.comments = data.comments.data
        data.comments.forEach((comment, index) => {
          if (comment.parentId) {
            data.comments.filter((filter, i) => {
              if (filter.id === comment.parentId) {
                if (!data.comments[i].hasOwnProperty('child')) data.comments[i].child = []
                data.comments[i].child.push(comment)
                delete data.comments[index]
                return filter
              }
              return false
            })

            return null
          }
          return comment
        })
        data.comments = data.comments.filter((n) => n)
      }
      ret = {data}
      break

    case DELETE:
      ret = {data: params}
      break

    default:
      if (data) ret = {data}
      break
  }
  return ret
}
