import React, {useEffect, useState} from 'react'

const TableDay = () => {
  const day = Array.from({length: 31}, (_, i) => i)
  return (
    <>
      <tr>
        <td colSpan={2}/>
        {day.map((i) => <td key={i} style={{fontSize: '10px'}}>D-{i}</td>)}
      </tr>
    </>
  )
}

export default ({data}) => {
  const [handleData, setHandleData] = useState(null)

  useEffect(() => {
    let tempData = []
    if (data) {
      const totalPointList = []
      const totalSavePointList = []
      let totalPoint = data.totalPoint
      let totalSavePoint = data.totalSavePoint

      data.data.forEach(p => {
        totalPointList.push(totalPoint)
        totalPoint -= (p.save + p.use)

        totalSavePointList.push(totalSavePoint)
        totalSavePoint -= p.save
      })

      tempData.push(totalPointList)
      tempData.push(totalSavePointList)
      setHandleData(tempData)
    }
  }, [data])
  const TableTr = (props) => {
    return (
      <tr>
        {props.components}
        <td>
          {props.label}
        </td>
        {handleData && handleData[props.id].map((text, id) => {
          return <td key={`${text}_${id}`} style={{fontSize: '12px'}}>{text}</td>
        })
        }
      </tr>
    )
  }

  return (<>
    <table className='dashboard-table' border="1" cellSpacing="0">
      <thead>
      <TableDay/>
      </thead>
      <tbody>
      <TableTr id={0} label={'현재 보유 포인트'} components={<td rowSpan={2}>
        Point 현황<br/>
      </td>}/>
      <TableTr id={1} label={'누적 보유 포인트'}/>
      </tbody>
    </table>
  </>)
}
