import {CREATE, DELETE, GET_LIST, GET_MANY, GET_ONE, UPDATE} from 'react-admin'
import {isNull, omitBy} from 'lodash'
import {convertImagePath, uploadToS3} from './ImageHandler'
import {ImageParse, ImageParseUpdate} from './ImageMethod'

let noticeId

export async function request(type, params) {
  let url = '/admin/v3/notices'
  const options = {}

  switch (type) {
    case UPDATE:
      url += `/${params.id}`
      params.data = omitBy(params.data, isNull)

    // eslint-disable-next-line no-fallthrough
    case CREATE:
      if (type === 'UPDATE') options.method = 'PUT'
      else options.method = 'POST'

      options.data = params.data
      //참여자 정보 추가
      //누적 -> 기본 , 당일 -> 삭제
      //누적 집계 없애고 엑셀로 처리
      //기본정보에 형태 와 옵션 추가
      const data = {...params.data}
      if (options.data.titleImagePath.image) {
        if (options.data.titleImagePath.image.substring(0, 5) === 'https') {
          options.data.titleImagePath.image = ImageParse(options.data.titleImagePath.image, 'notices')
        }
      }
      options.data = await uploadToS3(data, 'titleImagePath', 'notices', 'titleImagePath')
      options.data = await uploadToS3(data, 'images', 'notices', 'images')

      const src = options.data.titleImagePath.image
      options.data.titleImagePath = src

      if (options.data.titleImagePath.substring(0, 5) === 'https') {
        options.data.titleImagePath = ImageParseUpdate(options.data.titleImagePath, 'notices')
      }

      if (data.images) {
        data.images = data.images.map((image) => {
          if (image.hasOwnProperty('path')) return image.path
          return image
        })
      }

      if (options.data.images) {
        options.data.images = options.data.images.map((image) => {
          if (image.hasOwnProperty('image')) return image.image
          return image
        })
      }

      if (!options.data.images) options.data.images = []
      if (!options.data.createdAt) options.data.createdAt = new Date().toISOString()

      if (!options.data.form) {
        options.data.form = 'general'
      }
      break

    case GET_LIST:
      const {page, perPage} = params.pagination
      const {field, order} = params.sort
      options.params = {
        ...params.filter,
        sort: field,
        order,
        start: (page - 1) * perPage,
        perPage
      }
      break

    case GET_MANY:
      if (params.ids.length === 1) url += `/${params.ids[0]}`
      options.params = {
        perPage: 1000
      }
      break

    case GET_ONE:
      url += `/${params.id}`
      noticeId = params.id
      break

    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break

    case 'COMMENT':
      url += `/${params.id}/comments`
      options.method = 'POST'
      options.data = params.data
      break

    case 'EXCEL':
      url += `/${noticeId}`
      break

    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res
  switch (type) {
    case UPDATE:
      ret = params
      break

    case GET_MANY:
      if (params.ids.length === 1) ret = {data: [data]}
      else ret = data
      break

    case GET_LIST:
      data.data.forEach((row) => {
        if (row.titleImagePath !== '') {
          row.titleImagePath = convertImagePath(row.titleImagePath, 'large')
        }
      })
      ret = data
      break

    case GET_ONE:
      if (data.titleImagePath) {
        data.titleImagePath = convertImagePath(data.titleImagePath, 'large')
      }
      if (typeof data.titleImagePath === 'string') {
        const image = data.titleImagePath
        data.titleImagePath = {}
        data.titleImagePath.image = image
      }

      if (data.images) {
        data.images = data.images.map((image) => {
          return {path: image, image: convertImagePath(image, 'large')}
        })
      }

      if (data.hasOwnProperty('comments')) {
        data.comments = data.comments.data
        data.comments.forEach((comment, index) => {
          if (comment.parentId) {
            data.comments.filter((filter, i) => {
              if (filter.id === comment.parentId) {
                if (!data.comments[i].hasOwnProperty('child')) data.comments[i].child = []
                data.comments[i].child.push(comment)
                delete data.comments[index]
                return filter
              }
              return false
            })

            return null
          }
          return comment
        })
        data.comments = data.comments.filter((n) => n)
      }
      ret = {data}
      break

    case DELETE:
      ret = {data: params}
      break

    default:
      if (data) ret = {data}
      break
  }
  return ret
}
