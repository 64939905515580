import {CREATE, DELETE, GET_LIST, GET_MANY, GET_ONE, UPDATE} from 'react-admin'

let recentOptions

export function request(type, params) {
  let url = '/admin/points'
  let options = {}

  switch (type) {
    case CREATE:
      options.method = 'POST'
      options.data = params.data
      break

    case GET_LIST:
      const {page, perPage} = params.pagination
      const {field, order} = params.sort
      options.params = {
        ...params.filter,
        sort: field,
        order,
        start: (page - 1) * perPage,
        perPage
      }
      recentOptions = options
      break

    case GET_MANY:
      if (params.ids.length === 1) url += `/${params.ids[0]}`
      break

    case GET_ONE:
      url += `/${params.id}`
      break

    case UPDATE:
      url += `/${params.id}`
      options.method = 'PUT'
      options.data = params.data
      break

    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break

    case 'EXCEL':
      options = recentOptions
      let pg
      if (params.pagination) pg = params.pagination.perPage
      if (pg === 1000) {
        options.params.start = 0
        options.params.perPage = pg
      }
      options.params.perPage = 0
      break

    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res

  switch (type) {
    case UPDATE:
      ret = params
      break

    case GET_MANY:
      if (params.ids.length === 1) ret = {data: [data]}
      else ret = {data}
      break

    case GET_LIST:
      ret = data
      break

    case GET_ONE:
      ret = {data}
      break

    case DELETE:
      ret = {data: params}
      break

    default:
      if (data) ret = {data}
      break
  }
  return ret
}
