import React, {useEffect, useState} from "react";
import DateInput from "./DateInput";
import Chart from './Chart'
import PointChart from './PointChart'
import PointTable from './PointTable'
import Card from '@material-ui/core/Card'
import {dataProvider} from "../../App";
import {GET_ONE} from "react-admin";
import Table from './Table'
import {CircularProgress} from "@material-ui/core";


export const DashBoard = (props) => {
  const [dashboardData, setDashboardData] = useState({})
  useEffect(() => {
    const date = new Date()
    const yesterday = new Date(date.getTime());
    ;(async function () {
      try {
        const {data} = await dataProvider(GET_ONE, 'dashboard', {date: new Date(yesterday.setDate(date.getDate() - 1)).toISOString().slice(0, 10)})
        setDashboardData(data)
      } catch (e) {
        props.history.push('/login')
      }
    })()
  }, [props.history])
  return (
    <>
      <DateInput setDashboardData={setDashboardData} {...props}/>
        <div className="layout Layout-content" style={{flexWrap: 'wrap', display: 'flex'}}>
          <Card style={{height: '300px', width: '600px', margin: '1em'}}>
            {dashboardData.users ? <Chart label='회원가입' canvasId={0} data={dashboardData.users}/> : <CircularProgress style={{marginLeft:'290px', marginTop:'140px'}}/>}
          </Card>
          <Card style={{height: '300px', width: '600px', margin: '1em'}}>
            {dashboardData.users ? <Chart label='회원가입' canvasId={1} data={dashboardData.users} stack={true}/> : <CircularProgress style={{marginLeft:'290px', marginTop:'140px'}}/>}
          </Card>
          <Card style={{height: '300px', width: '600px', margin: '1em'}}>
            {dashboardData.users ? <Chart label='산책 고객 수' canvasId={2} data={dashboardData.walkUsers}/> : <CircularProgress style={{marginLeft:'290px', marginTop:'140px'}}/>}
          </Card>
          <Card style={{height: '300px', width: '600px', margin: '1em'}}>
            {dashboardData.users ? <Chart label='산책 고객 수' canvasId={3} data={dashboardData.walkUsers} stack={true}/> : <CircularProgress style={{marginLeft:'290px', marginTop:'140px'}}/>}
          </Card>
          <Card style={{height: '300px', width: '600px', margin: '1em'}}>
            {dashboardData.users ? <Chart label='Posting 수' canvasId={4} data={dashboardData.posts}/> : <CircularProgress style={{marginLeft:'290px', marginTop:'140px'}}/>}
          </Card>
          <Card style={{height: '300px', width: '600px', margin: '1em'}}>
            {dashboardData.users ? <Chart label='Posting 수' canvasId={5} data={dashboardData.posts} stack={true}/> : <CircularProgress style={{marginLeft:'290px', marginTop:'140px'}}/>}
          </Card>
          <Card style={{height: '300px', width: '600px', margin: '1em'}}>
            {dashboardData.users ? <Chart label='Posting 고객 수' canvasId={6} data={dashboardData.postUsers}/> : <CircularProgress style={{marginLeft:'290px', marginTop:'140px'}}/>}
          </Card>
          <Card style={{height: '300px', width: '600px', margin: '1em'}}>
            {dashboardData.users ? <Chart label='Posting 고객 수' canvasId={7} data={dashboardData.postUsers} stack={true}/> : <CircularProgress style={{marginLeft:'290px', marginTop:'140px'}}/>}
          </Card>
          <Card style={{height: '300px', width: '600px', margin: '1em'}}>
            {dashboardData.users ? <Chart label='매출 구매액' canvasId={8} data={dashboardData.sales}/> : <CircularProgress style={{marginLeft:'290px', marginTop:'140px'}}/>}
          </Card>
          <Card style={{height: '300px', width: '600px', margin: '1em'}}>
            {dashboardData.users ? <Chart label='매출 구매액' canvasId={9} data={dashboardData.sales} stack={true}/> : <CircularProgress style={{marginLeft:'290px', marginTop:'140px'}}/>}
          </Card>
          <Card style={{height: '300px', width: '600px', margin: '1em'}}>
            {dashboardData.users ? <Chart label='당일 구매자 수' canvasId={10} data={dashboardData.sales}/> : <CircularProgress style={{marginLeft:'290px', marginTop:'140px'}}/>}
          </Card>
          <Card style={{height: '300px', width: '600px', margin: '1em'}}>
            {dashboardData.users ? <Chart label='당일 구매자 수' canvasId={11} data={dashboardData.sales} stack={true}/> : <CircularProgress style={{marginLeft:'290px', marginTop:'140px'}}/>}
          </Card>
          <Card style={{height: '300px', width: '600px', margin: '1em'}}>
            {dashboardData.users ? <PointChart label='Point 현황' canvasId={12} data={dashboardData.points}/> : <CircularProgress style={{marginLeft:'290px', marginTop:'140px'}}/>}
          </Card>
        </div>
        <Card style={{overflow: 'scroll', height: '315px', width: '1250px', margin: '1em'}}>
        <PointTable data={dashboardData.points}/>
        </Card>

        <Card style={{overflowX: 'scroll', height: '395px', width: '1250px', margin: '1em'}}>
        <Table data={dashboardData.topSalePrice} type='가격'/>
        </Card>
        <Card style={{overflowX: 'scroll', height: '395px', width: '1250px', margin: '1em'}}>
        <Table data={dashboardData.topSaleCount} type='개수'/>
        </Card>
    </>)
}
