import {CREATE, DELETE, GET_LIST, GET_MANY, GET_ONE, UPDATE} from 'react-admin'
import {isNull, omitBy} from 'lodash'
import {convertImagePath, uploadToS3} from './ImageHandler'
import {ImageParse, ImageParseUpdate} from './ImageMethod'

let recentOptions
//eslint-disable-next-line
let seedCampaignBestReviewId

export async function request(type, params) {
  let url = '/admin/v3/seedCampaign/review'
  let options = {}

  switch (type) {
    case UPDATE:
      url += `/${params.id}`
      params.data = omitBy(params.data, isNull)

    // eslint-disable-next-line no-fallthrough
    case CREATE:
      if (type === 'UPDATE') options.method = 'PUT'
      else options.method = 'POST'
      options.data = params.data
      const data = {...params.data}

      if (options.data.titleImage.image) {
        if (options.data.titleImage.image.substring(0, 5) === 'https') {
          options.data.titleImage.image = ImageParse(options.data.titleImage.image, 'seedCampaign')
        }
      }

      options.data = await uploadToS3(data, 'titleImage', 'seedCampaign', 'titleImage')
      options.data = await uploadToS3(data, 'detailImages', 'seedCampaign', 'detailImages')

      const src = options.data.titleImage.image
      options.data.titleImage = src

      if (options.data.titleImage.substring(0, 5) === 'https') {
        options.data.titleImage = ImageParseUpdate(options.data.titleImage, 'seedCampaign')
      }

      if (data.detailImages) {
        data.detailImages = data.detailImages.map((image) => {
          if (image.hasOwnProperty('path')) return image.path
          return image
        })
      }
      if (options.data.detailImages) {
        options.data.detailImages = options.data.detailImages.map((image) => {
          if (image.hasOwnProperty('image')) return image.image
          return image
        })
      }

      if (!options.data.detailImages) options.data.detailImages = []
      if (!options.data.createdAt) options.data.createdAt = new Date().toISOString()

      break

    case GET_LIST:
      const {page, perPage} = params.pagination
      const {field, order} = params.sort

      options.params = {
        ...params.filter,
        sort: field,
        order,
        start: (page - 1) * perPage,
        perPage
      }
      recentOptions = options
      break

    case GET_MANY:
      if (params.ids.length === 1) url += `/${params.ids[0]}`
      break

    case GET_ONE:
      url += `/${params.id}`
      //eslint-disable-next-line
      seedCampaignBestReviewId = params.id
      break

    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break

    case 'COMMENT':
      url += `/${params.id}/comments`
      seedCampaignBestReviewId = params.id
      options.method = 'GET'
      break

    case 'POST_COMMENT':
      url += `/${params.id}/comments`
      seedCampaignBestReviewId = params.id
      options.data = params.data
      options.method = 'POST'
      break

    case 'EXCEL':
      options = recentOptions
      options.params.perPage = undefined
      break

    case 'ENTRY':
      url += `/${params.id}`
      seedCampaignBestReviewId = params.id
      break

    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res

  switch (type) {
    case UPDATE:
      ret = params
      break

    case GET_MANY:
      ret = {data}
      break

    case GET_LIST:
      data.data.forEach((row) => {
        if (row.titleImage !== '') {
          row.titleImage = convertImagePath(row.titleImage, 'large')
        }
      })
      ret = data
      break

    case GET_ONE:
      if (data.titleImage) {
        data.titleImage = convertImagePath(data.titleImage, 'large')
      }
      if (typeof data.titleImage === 'string') {
        const image = data.titleImage
        data.titleImage = {}
        data.titleImage.image = image
      }
      if (data.detailImages) {
        data.detailImages = data.detailImages.map((image) => {
          return {path: image, image: convertImagePath(image, 'large')}
        })
      }

      if (data.hasOwnProperty('comments')) {
        data.comments = data.comments.data
        data.comments.forEach((comment, index) => {
          if (comment.parentId) {
            data.comments.filter((filter, i) => {
              if (filter.id === comment.parentId) {
                if (!data.comments[i].hasOwnProperty('child')) data.comments[i].child = []
                data.comments[i].child.push(comment)
                delete data.comments[index]
                return filter
              }
              return false
            })

            return null
          }
          return comment
        })
        data.comments = data.comments.filter((n) => n)
      }
      ret = {data}
      break

    case DELETE:
      ret = {data: params}
      break

    default:
      if (data) ret = {data}
      break
  }

  return ret
}
