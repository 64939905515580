import {CREATE, DELETE, GET_LIST, GET_MANY, GET_ONE, UPDATE,} from 'react-admin'

// import {convertImagePath} from './ImageHandler'

export function request(type, params) {
  let url = '/admin/admins'
  let options = {}
  switch (type) {

    case CREATE:
      options.method = 'POST'
      options.data = params.data
      break

    case GET_LIST:
      const {page, perPage} = params.pagination
      const {field, order} = params.sort
      options.params = {
        ...params.filter,
        sort: field,
        order: order,
        start: (page - 1) * perPage,
        perPage: perPage,
      }
      break

    case GET_MANY:
      if (params.ids.length === 1) url += `/${params.ids[0]}/info`
      break
    case GET_ONE:
      url += `/${params.id}`
      break
    case UPDATE:
      url += `/${params.id}`
      options.method = 'PUT'
      options.data = params.data
      break
    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break
    default:
      break
  }
  if (localStorage.getItem('auth') !== 'master') {
    if (parseInt(params.id) === 1) url = ""
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res

  switch (type) {
    case UPDATE:
      ret = params
      break

    case GET_MANY:
      if (params.ids.length === 1) ret = {data: [data]}
      else ret = {data}
      break

    case GET_LIST:
      const permission = localStorage.getItem('auth')

      if (permission !== 'master') {
        let id = 0
        data.data.forEach((user, index) => {
          if (user.name === 'master') id = index
        })
        data.data.splice(id, 1)
      }

      if (permission === 'admin') {
        const userName = localStorage.getItem('userName')
        data.data = data.data.filter((e) => {
          return e.name === userName
        })
      }

      ret = data
      break

    case GET_ONE:
      // data.devices.forEach(row => row.titleImage = convertImagePath(row.titleImage, 'small'))
      ret = {data}
      break

    case DELETE:
      ret = {data: params}
      break

    default:
      if (data) ret = {data}
      break
  }

  return ret
}
