import {GET_LIST} from 'ra-core'
import {CREATE, GET_MANY, GET_ONE} from 'react-admin'

export const request = (type, params) => {
  let url = '/admin/messages'
  const options = {}
  switch (type) {
    case CREATE:
      options.method = 'post'
      options.data = params
      break
    case GET_LIST:
      url += '/received'
      const {page, perPage} = params.pagination
      const {field, order} = params.sort
      options.params = {
        ...params.filter,
        sort: field,
        order,
        start: (page - 1) * perPage,
        perPage
      }
      break
    case GET_MANY:
      if (params.ids.length === 1) url += `/received/${params.ids[0]}`
      break
    case GET_ONE:
      url += `/received/${params.id}`
      break
    case 'PUSH':
      options.data = params.data
      options.method = 'POST'
      break
    default:
      break
  }
  return {url, ...options}
}

export const response = (res, type, params) => {
  let ret = {}
  const {data} = res
  switch (type) {
    case GET_MANY:
      if (params.ids.length === 1) ret = {data: [data]}
      else ret = data
      break
    case GET_LIST:
      ret = data
      break
    case GET_ONE:
      ret = {data}
      break
    default:
      if (data) ret = {data}
      break
  }
  return ret
}
