import moment from 'moment'
import {CREATE, DELETE, GET_LIST, GET_MANY, GET_ONE, UPDATE} from 'react-admin'
import {isNull, omitBy} from 'lodash'
import {convertImagePath, uploadToS3} from './ImageHandler'

export async function request(type, params) {
  let url = '/admin/sellerNotices'
  const options = {}

  switch (type) {
    case UPDATE:
      url += `/${params.id}`
      params.data = omitBy(params.data, isNull)
      break

    case CREATE:
      if (type === 'UPDATE') options.method = 'PUT'
      else options.method = 'POST'
      const data = {...params.data}
      options.data = await uploadToS3(data, 'images', 'sellerNotices', 'images')

      if (data.images) {
        data.images = data.images.map((image) => {
          if (image.hasOwnProperty('path')) return image.path
          return image
        })
      }

      if (options.data.images) {
        options.data.images = options.data.images.map((image) => {
          if (image.hasOwnProperty('image')) return image.image
          return image
        })
      }

      if (!options.data.images) options.data.images = []
      if (!options.data.createdAt) options.data.createdAt = new Date().toISOString()
      break

    case GET_LIST:
      const {page, perPage} = params.pagination
      const {field, order} = params.sort

      options.params = {
        ...params.filter,
        sort: field,
        order,
        start: (page - 1) * perPage,
        perPage
      }
      break

    case GET_MANY:
      if (params.ids.length === 1) url += `/${params.ids[0]}`
      options.params = {
        perPage: 1000
      }
      break

    case GET_ONE:
      url += `/${params.id}`
      // eslint-disable-next-line
      const noticeId = params.id
      break

    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break

    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res

  switch (type) {
    case UPDATE:
      ret = params
      break

    case GET_MANY:
      if (params.ids.length === 1) ret = {data: [data]}
      else ret = data
      break

    case GET_LIST:
      const currDay = moment()

      const items = data.data
      items.map((e) => {
        const noticeDay = moment(e.createdAt)
        if (currDay.diff(noticeDay, 'days') < 3) return (e.isNew = 'NEW')
        return (e.isNew = '')
      })
      ret = data
      break

    case GET_ONE:
      if (data.images) {
        data.images = data.images.map((image) => {
          return {path: image, image: convertImagePath(image, 'large')}
        })
      }

      if (data.hasOwnProperty('comments')) {
        data.comments = data.comments.data
        data.comments.forEach((comment, index) => {
          if (comment.parentId) {
            data.comments.filter((filter, i) => {
              if (filter.id === comment.parentId) {
                if (!data.comments[i].hasOwnProperty('child')) data.comments[i].child = []
                data.comments[i].child.push(comment)
                delete data.comments[index]
                return filter
              }
              return false
            })

            return null
          }
          return comment
        })
        data.comments = data.comments.filter((n) => n)
      }
      ret = {data}
      break

    case DELETE:
      ret = {data: params}
      break

    default:
      if (data) ret = {data}
      break
  }

  return ret
}
