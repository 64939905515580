import {DELETE, GET_LIST, GET_MANY, GET_ONE, UPDATE, CREATE} from 'react-admin'
import {convertImagePath, uploadToS3} from './ImageHandler'

export async function request(type, params) {
  let url = '/admin/v3/advertising'
  const options = {}
  // eslint-disable-next-line
  let advertisingId

  switch (type) {
    case UPDATE:
    case CREATE:
      if (type === UPDATE) {
        url += `/${params.id}`
        options.method = 'PUT'
      } else options.method = 'POST'
      const data = {...params.data}
      options.data = await uploadToS3(data, 'images', 'advertising', 'images')

      if (data.images) {
        data.images = data.images.map((image) => {
          if (image.hasOwnProperty('path')) return image.path
          return image
        })
      }

      if (options.data.images) {
        options.data.images = options.data.images.map((image) => {
          if (image.hasOwnProperty('image')) return image.image
          return image
        })
      }

      if (!options.data.images) options.data.images = []
      if (!options.data.createdAt) options.data.createdAt = new Date().toISOString()
      break

    case GET_LIST:
      const {page, perPage} = params.pagination
      const {field, order} = params.sort

      options.params = {
        ...params.filter,
        sort: field,
        order,
        start: (page - 1) * perPage,
        perPage
      }
      break

    case GET_MANY:
      if (params.ids.length === 1) url += `/${params.ids[0]}`
      break

    case GET_ONE:
      url += `/${params.id}`
      advertisingId = params.id
      break

    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break

    case 'COMMENT':
      url += `/${params.id}/comments`
      advertisingId = params.id
      options.method = 'POST'
      options.data = params.data
      break

    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res

  switch (type) {
    case UPDATE:
      ret = params
      break

    case GET_MANY:
      if (params.ids.length === 1) ret = {data: [data]}
      else ret = data
      break

    case GET_LIST:
      const handleData = data.data.map((item) => {
        if (item.images.length > 0) {
          item.thumbnailImage = convertImagePath(item.images[0])
        }
        return item
      })

      ret = {data: handleData, total: data.total}
      break

    case GET_ONE:
      if (data.images) {
        data.images = data.images.map((image) => {
          return {path: image, image: convertImagePath(image, 'large')}
        })
      }

      if (data.hasOwnProperty('comments')) {
        data.comments = data.comments.data
        data.comments.forEach((comment, index) => {
          if (comment.parentId) {
            data.comments.filter((filter, i) => {
              if (filter.id === comment.parentId) {
                if (!data.comments[i].hasOwnProperty('child')) data.comments[i].child = []
                data.comments[i].child.push(comment)
                delete data.comments[index]
                return filter
              }
              return false
            })

            return null
          }
          return comment
        })
        data.comments = data.comments.filter((n) => n)
      }
      data.exhibitionId = data.eventId
      ret = {data}
      break

    case DELETE:
      ret = {data: params}
      break

    default:
      if (data) ret = {data}
      break
  }
  return ret
}
