import BookIcon from '@material-ui/icons/Book'
import DonationCreate from './DonationDestinationCreate'
import DonationEdit from './DonationDestinationEdit'
import DonationShow from './DonationDestinationShow'
import DonationList from './DonationDestinationList'

export default {
  list: DonationList,
  create: DonationCreate,
  edit: DonationEdit,
  show: DonationShow,
  icon: BookIcon
}
