import BookIcon from '@material-ui/icons/Book'
import KnowledgeCreate from './KnowledgeCreate'
import KnowledgeEdit from './KnowledgeEdit'
import KnowledgeShow from './KnowledgeShow'
import KnowledgeList from './KnowledgeList'

export default {
  list: KnowledgeList,
  create: KnowledgeCreate,
  edit: KnowledgeEdit,
  show: KnowledgeShow,
  icon: BookIcon,
}
