import {CREATE, DELETE, GET_LIST, GET_MANY, GET_ONE, UPDATE} from 'react-admin'
import {convertImagePath, uploadToS3} from './ImageHandler'
import {ImageParse} from './ImageMethod'

export async function request(type, params) {
  let url = '/admin/v3/popups'
  const options = {}
  // eslint-disable-next-line
  let popupId

  switch (type) {
    case UPDATE:
    case CREATE:
      if (type === UPDATE) {
        url += `/${params.id}`
        options.method = 'PUT'
      } else options.method = 'POST'
      options.data = params.data

      const data = {...params.data}
      if (options && options.data && options.data.imagePath) {
        if (options.data.imagePath.image) {
          if (options.data.imagePath.image.substring(0, 5) === 'https') {
            options.data.imagePath.image = ImageParse(options.data.imagePath.image, 'popups')
          }
        }
        options.data = await uploadToS3(data, 'imagePath', 'popups', 'imagePath')
        const src = options.data.imagePath.image
        options.data.imagePath = ''
        options.data.imagePath = src
        if (options.data.imagePath.substring(0, 5) === 'https') {
          options.data.imagePath = ImageParse(options.data.imagePath, 'popups')
        }
      }
      if (!data.content) {
        options.data.content = '-'
      }

      if (!options.data.imagePath) {
        options.data.imagePath = ''
      }
      break

    case 'DUPLICATE': {
      options.method = 'POST'
      options.data = params.data

      const data = {...params.data}
      if (options && options.data && options.data.imagePath) {
        if (options.data.imagePath.image) {
          if (options.data.imagePath.image.substring(0, 5) === 'https') {
            options.data.imagePath.image = ImageParse(options.data.imagePath.image, 'popups')
          }
        }
        options.data = await uploadToS3(data, 'imagePath', 'popups', 'imagePath')
        const src = options.data.imagePath.image
        options.data.imagePath = ''
        options.data.imagePath = src
        if (options.data.imagePath.substring(0, 5) === 'https') {
          options.data.imagePath = ImageParse(options.data.imagePath, 'popups')
        }
      }
      if (!data.content) {
        options.data.content = '-'
      }

      if (!options.data.imagePath) {
        options.data.imagePath = ''
      }
      break
    }

    case GET_LIST:
      const {page, perPage} = params.pagination
      const {field, order} = params.sort
      options.params = {
        ...params.filter,
        sort: field,
        order,
        start: (page - 1) * perPage,
        perPage
      }
      break

    case GET_MANY:
      if (params.ids.length === 1) url += `/${params.ids[0]}`
      break

    case GET_ONE:
      url += `/${params.id}`
      popupId = params.id
      break

    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      popupId = params.id
      break

    default:
      break
  }

  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res

  switch (type) {
    case UPDATE:
      ret = params
      break

    case GET_MANY:
      if (params.ids.length === 1) ret = {data: [data]}
      else ret = {data}
      break

    case GET_ONE:
      data.imagePath = convertImagePath(data.imagePath, 'large')
      if (typeof data.imagePath === 'string') {
        const image = data.imagePath
        data.imagePath = {}
        data.imagePath.image = image
      }
      if (data.type === 'events') data.exhibitionId = Number(data.eventId)
      data.exhibitionId = data.eventId
      ret = {data}
      break

    case GET_LIST:
      ret = data
      break

    case DELETE:
      ret = {data: params}
      break

    default:
      if (data) ret = {data}
      break
  }
  return ret
}
