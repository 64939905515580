import React from 'react'
import {DateField, SelectField, Show, SimpleShowLayout, TextField, translate, BooleanField} from 'react-admin'
import ImageClickField from '../../custom/common/ImageClickField'
import ManagerShowActions from '../../custom/common/ManagerShowActions'

class DonationDestinationShow extends React.Component {
  render() {
    const {...props} = this.props
    return (
      <Show {...props} actions={<ManagerShowActions />}>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="name" />
          <ImageClickField label="이미지" addLabel={true} source="imagePath" />
          {/*배너 위치*/}
          {/*랜딩 페이지*/}
          <SelectField
            source="type"
            choices={[
              {id: 'events', name: '이벤트'},
              {id: 'notice', name: '공지사항'},
              {id: 'exhibition', name: '기획전'},
              {id: 'community', name: '커뮤니티 상세페이지'},
              {id: 'url', name: '링크'},
              {id: 'image', name: '이미지'},
              {id: 'walk', name: '산책'},
              {id: 'myPage', name: '마이 페이지'},
              {id: 'seedCampaignMain', name: '체험단 메인'},
              {id: 'postingCommunity', name: '커뮤니티 게시글 상세'},
              {id: 'postingNotice', name: '공지사항 게시글 상세'},
              {id: 'postingEvent', name: '이벤트 게시글 상세'},
              {id: 'shoppingExhibition', name: '쇼핑 기획전'}
            ]}
          />
          {/*해당 ID가 어느 DB에 ID인지 판별이 현재 버전에서는 안됨*/}
          <TextField source="content" />
          <TextField source="adUrl" />
          <TextField source="position" />
          <TextField source="order" />
          <BooleanField source="onOff" />
          <TextField source="userClick" />
          <BooleanField source="isAd" />
          <BooleanField source="isOfferwall" />
          <TextField source="rewardPoint" />
          <DateField source="startDate" locales="ko" showTime sortable={false} />
          <DateField source="endDate" locales="ko" showTime sortable={false} />
          <DateField source="createdAt" />
        </SimpleShowLayout>
      </Show>
    )
  }
}

export default translate(DonationDestinationShow)
