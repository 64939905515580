import React from 'react'
import {
  BooleanField,
  DateField,
  SelectField,
  Show,
  SimpleShowLayout,
  TextField,
  translate,
} from 'react-admin'
import ShowActions from '../../custom/common/ShowActions'
import ImageClickField from "../../custom/common/ImageClickField"

class ReviewShow extends React.Component {
  render() {
    const {...props} = this.props
    return (
        <Show {...props} actions={<ShowActions/>}>
          <SimpleShowLayout>

            <TextField source="id" />
            <TextField source="title" />
            <TextField source="subTitle" />
            <TextField source="content" />
            <SelectField source="type" choices={[{id: 'text', name: 'text'}, {id: 'url', name: 'url'}]}/>
            <ImageClickField label='대표 이미지' addLabel={true}
                             source="thumbnailPath"/>
            <ImageClickField label='상세 이미지' addLabel={true}
                             source="images"/>

            <BooleanField source="isAdOn"/>
            <DateField source="createdAt" locales='ko' showTime/>
          </SimpleShowLayout>
        </Show>
    )
  }
}

export default translate(ReviewShow)
