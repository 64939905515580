import React from "react";
import {GET_ONE} from 'react-admin'
import {withStyles} from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField'
import {dataProvider} from "../../App";

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
});

const DateInput = (props) => {

  const date = new Date()
  const yesterday = new Date(date.getTime());

  const {classes} = props;
  const dateHandle = (e) => {
    dataProvider(GET_ONE, 'dashboard', {date: e.target.value}).then(({data}) => {
      props.setDashboardData(data)
    }).catch((e) => {
      props.history.push('/login')
    })
  }
  return (<>
      <form className={classes.container}>
        <TextField
          onChange={dateHandle}
          id="date"
          label="금일 날짜"
          type="date"
          className={classes.textField}
          defaultValue={new Date(yesterday.setDate(date.getDate() - 1)).toISOString().slice(0,10)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </form>
    </>
  )
}
export default withStyles(styles)(DateInput);
