import {CREATE, DELETE, GET_LIST, GET_MANY, GET_ONE, UPDATE} from 'react-admin'
import {convertImagePath, uploadToS3} from './ImageHandler'
import {ImageParse, ImageParseUpdate} from './ImageMethod'
//eslint-disable-next-line
let recentOptions
//eslint-disable-next-line
let designSourceId

export async function request(type, params) {
  let url = '/admin/v3/designSource'
  const options = {}

  switch (type) {
    case UPDATE:
      url += `/${params.id}`
      options.method = 'PUT'
      options.data = params.data

    // eslint-disable-next-line no-fallthrough
    case CREATE:
      if (type === 'UPDATE') options.method = 'PUT'
      else options.method = 'POST'
      options.data = params.data
      const data = {...params.data}

      if (options.data.titleImage.image) {
        if (options.data.titleImage.image.substring(0, 5) === 'https') {
          options.data.titleImage.image = ImageParse(options.data.titleImage.image, 'designSource')
        }
      }

      options.data = await uploadToS3(data, 'titleImage', 'designSource', 'titleImage')

      const src = options.data.titleImage.image
      options.data.titleImage = src

      if (options.data.titleImage.substring(0, 5) === 'https') {
        options.data.titleImage = ImageParseUpdate(options.data.titleImage, 'designSource')
      }

      if (!options.data.createdAt) options.data.createdAt = new Date().toISOString()

      break

    case GET_LIST:
      const {page, perPage} = params.pagination
      const {field, order} = params.sort

      options.params = {
        ...params.filter,
        sort: field,
        order,
        start: (page - 1) * perPage,
        perPage
      }
      recentOptions = options
      break

    case GET_MANY:
      if (params.ids.length === 1) url += `/${params.ids[0]}`
      options.params = {
        perPage: 1000
      }
      break

    case GET_ONE:
      url += `/${params.id}`
      //eslint-disable-next-line
      designSourceId = params.id
      break

    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break

    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res

  switch (type) {
    case UPDATE:
      ret = params
      break

    case GET_MANY:
      if (params.ids.length === 1) ret = {data: [data]}
      else ret = data
      break

    case GET_LIST:
      data.data.forEach((row) => {
        if (row.titleImage !== '') {
          row.titleImage = convertImagePath(row.titleImage, 'large')
        }
      })
      ret = data
      break

    case GET_ONE:
      if (data.titleImage) {
        data.titleImage = convertImagePath(data.titleImage, 'large')
      }
      if (typeof data.titleImage === 'string') {
        const image = data.titleImage
        data.titleImage = {}
        data.titleImage.image = image
      }
      ret = {data}
      break

    case DELETE:
      ret = {data: params}
      break

    default:
      if (data) ret = {data}
      break
  }
  return ret
}
