import BookIcon from '@material-ui/icons/Book'
import DonationCreate from './DonationCreate'
import DonationEdit from './DonationEdit'
import DonationShow from './DonationShow'
import DonationList from './DonationList'

export default {
  list: DonationList,
  create: DonationCreate,
  edit: DonationEdit,
  show: DonationShow,
  icon: BookIcon
}
