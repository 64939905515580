import {
  CREATE,
  DELETE,
  GET_LIST,
  GET_MANY,
  GET_ONE,
  UPDATE,
} from 'react-admin'
import {convertImagePath, uploadToS3} from './ImageHandler'
import {ImageParse} from "./ImageMethod"

export async function request(type, params) {
  let url = '/admin/services'
  let options = {}

  switch (type) {
    case UPDATE:
    case CREATE:
      if (type === UPDATE) {
        url += `/${params.id}`
        options.method = 'PUT'
      } else
        options.method = 'POST'

      options.data = params.data
      const data = {...params.data}
      if (options.data.imagePath.image) {
        if (options.data.imagePath.image.substring(0, 4) === 'http') {
          options.data.imagePath.image = ImageParse(options.data.imagePath.image, 'service')
        }
      }
      options.data = await uploadToS3(data, 'imagePath', 'service', 'imagePath',)

      const src = options.data.imagePath.image
      options.data.imagePath = src

      if (options.data.imagePath.substring(0, 4) === 'http') {
        options.data.imagePath = ImageParse(options.data.imagePath, 'service')
      }
      break

    case GET_LIST:
      const {page, perPage} = params.pagination
      const {field, order} = params.sort
      options.params = {
        ...params.filter,
        sort: field,
        order: order,
        start: (page - 1) * perPage,
        perPage: perPage,
      }
      break

    case GET_MANY:
      if (params.ids.length === 1) url += `/${params.ids[0]}`
      break

    case GET_ONE:
      url += `/${params.id}`
      break

    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break

    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res
  switch (type) {
    case UPDATE:
      ret = params
      break

    case GET_MANY:
      if (params.ids.length === 1) ret = {data: [data]}
      else ret = {data}
      break

    case GET_ONE:
      if (!data.imagePath) data.imagePath = ''
      else {
        data.imagePath = convertImagePath(data.imagePath,'large')
        if (typeof data.imagePath === 'string') {
          const image = data.imagePath
          data.imagePath = {}
          data.imagePath.image = image
        }
      }
      ret = {data}
      break

    case GET_LIST:
      ret =data
      break

    case DELETE:
      ret = {data: params}
      break

    default:
      if (data) ret = {data}
      break
  }
  return ret
}
