import {CREATE, DELETE, GET_LIST, GET_MANY, GET_ONE, UPDATE} from 'react-admin'
import {convertImagePath} from './ImageHandler'

export async function request(type, params) {
  let url = '/admin/productQAs'
  const options = {}

  switch (type) {
    case UPDATE:
    case CREATE:
      if (type === UPDATE) {
        url += `/${params.id}`
        options.method = 'PUT'
      } else options.method = 'POST'
      options.data = params.data
      /*
      let data = {...params.data}
      if (options.data.thumbnailPath.image) {
        if (options.data.thumbnailPath.image.substring(0, 5) === 'https') {
          options.data.thumbnailPath.image = ImageParse(options.data.thumbnailPath.image, 'reviews')
        }
      }

      options.data = await uploadToS3(data, 'thumbnailPath', 'reviews', 'thumbnailPath',)
      options = await cutImages(options, data)

      data = options.data
      options.data = await uploadToS3(data, 'images', 'reviews', 'images',)
      const src = options.data.thumbnailPath.image
      options.data.thumbnailPath = src

      const dataImage = options.data.images.map((value) => {
        const temp = value.image
        return temp
      })
      options.data.images = dataImage

      if (options.data.thumbnailPath.substring(0, 5) === 'https') {
        options.data.thumbnailPath = ImageParse(options.data.thumbnailPath, 'reviews')
      }

      const parse = options.data.images.map((v) => {
        if (v.substring(0, 5) === 'https') {
          v = ImageParse(v, 'reviews')
        }
        return v
      })
      options.data.images = parse
 */
      break

    case GET_LIST:
      const {page, perPage} = params.pagination
      const {field, order} = params.sort
      options.params = {
        ...params.filter,
        sort: field,
        order,
        start: (page - 1) * perPage,
        perPage
      }
      break

    case GET_MANY:
      if (params.ids.length === 1) url += `/${params.ids[0]}`
      break

    case GET_ONE:
      url += `/${params.id}`
      break

    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break

    case 'EXCEL':
      options.params = {
        start: 0,
        perPage: 100 //@@ excel download identifier
      }
      break

    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res

  switch (type) {
    case UPDATE:
      ret = params
      break

    case GET_MANY:
      if (params.ids.length === 1) ret = {data: [data]}
      else ret = {data}
      break

    case GET_ONE:
      data.data.images = data.data.images.map((value) => {
        return {image: convertImagePath(value, 'large')}
      })
      ret = data
      break

    case GET_LIST:
      data.data.forEach((row) => (row.thumbnail = convertImagePath(row.titleImagePath, 'thumbnail')))
      ret = data
      break

    case DELETE:
      ret = {data: params}
      break

    case 'EXCEL':
      //data.data.forEach(row => row.thumbnail = convertImagePath(row.thumbnail, 'large'))
      ret = data
      break

    default:
      if (data) ret = {data}
      break
  }

  return ret
}
