import {
  CREATE,
  DELETE,
  GET_LIST,
  GET_MANY,
  GET_ONE,
  UPDATE,
} from 'react-admin'

export function request(type, params) {
  let url = '/admin/dashboard'
  let options = {}

  switch (type) {

    case CREATE:
      options.method = 'POST'
      options.data = params.data
      break
    case GET_LIST:
      const {page, perPage} = params.pagination
      const {field, order} = params.sort
      options.params = {
        ...params.filter,
        sort: field,
        order: order,
        start: (page - 1) * perPage,
        perPage: perPage,
      }
      break
    case GET_MANY:
      if (params.ids.length === 1) url += `/${params.ids[0]}/info`
      break
    case GET_ONE:
      options.params = params
      break
    case UPDATE:
      url += `/${params.id}`
      options.method = 'PUT'
      options.data = params.data
      break
    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break

    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res

  switch (type) {
    case UPDATE:
      ret = params
      break

    case GET_MANY:
      if (params.ids.length === 1) ret = {data: [data]}
      else ret = {data}
      break

    case GET_LIST:
      ret = data
      break

    case GET_ONE:
      let days = data.walkUsers.data
      let sum = data.walkUsers.startSum

      days = data.postUsers.data
      sum = data.postUsers.startSum
      days.reverse()
      days.forEach((e) => {
        sum += e.count > 0 ? e.count : 0
        e.total = sum
      })
      days.reverse()

      days = data.sales.data
      sum = data.sales.startSum
      days.reverse()
      days.forEach((e) => {
        sum += e.users > 0 ? e.users : 0
        e.totalUsers = sum
      })
      days.reverse()

      ret = {data}
      break

    case DELETE:
      ret = {data: params}
      break

    default:
      if (data) ret = {data}
      break
  }
  return ret
}
