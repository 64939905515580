import React from 'react'
import {
  BooleanInput,
  Create,
  DateTimeInput,
  FormDataConsumer,
  ImageField,
  ImageInput,
  LongTextInput,
  maxLength,
  minLength,
  NumberInput,
  ReferenceInput,
  regex,
  SelectInput,
  SimpleForm,
  TextInput
} from 'react-admin'
import {required} from '../../validators'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'

export default (props) => (
  <Create {...props} actions={<CreateActions />}>
    <SimpleForm toolbar={<CreateToolbar />}>
      <TextInput source="name" validate={[required, maxLength(100), minLength(1)]} />
      <ImageInput source="imagePath" accept="image/*" validate={[required]}>
        <ImageField source="image" title="title" />
      </ImageInput>
      {/*랜딩 페이지*/}
      <SelectInput
        source="type"
        choices={[
          {id: 'events', name: '이벤트'},
          {id: 'notice', name: '공지사항'},
          {id: 'exhibition', name: '기획전'},
          {id: 'community', name: '커뮤니티 상세페이지'},
          {id: 'url', name: '링크'},
          {id: 'image', name: '이미지'},
          {id: 'walk', name: '산책'},
          {id: 'myPage', name: '마이 페이지'},
          {id: 'seedCampaignMain', name: '체험단 메인'},
          {id: 'postingCommunity', name: '커뮤니티 게시글 상세'},
          {id: 'postingNotice', name: '공지사항 게시글 상세'},
          {id: 'postingEvent', name: '이벤트 게시글 상세'},
          {id: 'shoppingExhibition', name: '쇼핑 기획전'}
        ]}
        validate={[required]}
      />

      <FormDataConsumer>
        {({formData, ...rest}) => {
          return (
            <>
              {formData && formData.type === 'exhibition' ? (
                <ReferenceInput
                  label="기획전"
                  source="content"
                  reference="exhibition"
                  validate={required}
                  perPage={200}
                >
                  <SelectInput optionText="title" />
                </ReferenceInput>
              ) : formData && formData.type === 'notice' ? (
                <ReferenceInput label="공지사항" source="content" reference="notices" validate={required} perPage={200}>
                  <SelectInput optionText="title" />
                </ReferenceInput>
              ) : formData && formData.type === 'events' ? (
                <ReferenceInput label="이벤트" source="content" reference="events" validate={required} perPage={200}>
                  <SelectInput optionText="title" />
                </ReferenceInput>
              ) : (
                //TODO - 체험단 내용
                <LongTextInput
                  label="추가적인 내용을 입력해주세요."
                  source="content"
                  validate={[required, minLength(1)]}
                />
              )}

              {formData && formData.type === 'url' ? (
                <TextInput
                  label="광고 파라미터"
                  source="adUrl"
                  validate={[maxLength(100), regex(/^[a-zA-Z]*$/, '영문자만 가능합니다.')]}
                />
              ) : (
                <div></div>
              )}
              <div>
                <SelectInput
                  source="target"
                  label="배너위치"
                  choices={[
                    {id: 'landing', name: '❌온 보딩❌'},
                    {id: 'talk', name: '❌게시판 탭❌'},
                    {id: 'community', name: '❌커뮤니티 배너❌'},
                    {id: 'product', name: '❌쇼핑 > 서브배너❌'}, //쇼핑 중간 미디움 배너
                    {id: 'product1', name: '❌쇼핑 > 메인배너❌'}, //쇼핑 상단 라지 배너
                    {id: 'walk', name: '❌산책 띠배너❌'},
                    {id: 'home', name: '홈 > 중간배너'},
                    {id: 'communityList', name: '커뮤니티 리스트'},
                    {id: 'communityDetail', name: '커뮤니티 상세'},
                    {id: 'shoppingMain', name: '쇼핑 > 상단 메인배너'},
                    {id: 'shoppingMiddle', name: '쇼핑 > 중간 서브배너'},
                    {id: 'seedCampaign', name: '체험단'},
                    {id: 'myPage', name: '마이 페이지'},
                    {id: 'walkReady', name: '산책 시작 전'},
                    {id: 'walkCalendar', name: '산책 일지'},
                    {id: 'onBoarding', name: '앱 시작 화면'}
                  ]}
                  validate={[required]}
                />
              </div>
            </>
          )
        }}
      </FormDataConsumer>
      <NumberInput source="order" validate={required} />
      <BooleanInput source="onOff" validate={[required]} defaultValue={false} />

      <DateTimeInput source="startDate" locales="ko" validate={[required]} defaultValue={new Date()} />
      <DateTimeInput source="endDate" locales="ko" validate={[required]} defaultValue={new Date()} />
    </SimpleForm>
  </Create>
)
