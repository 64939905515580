import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  BooleanField,
  CardActions,
  CreateButton,
  Datagrid,
  DateField,
  Filter,
  ImageField,
  List,
  SearchInput,
  SelectField,
  TextField
} from 'react-admin'

const styles = (theme) => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
})

const Actions = ({basePath}) => {
  const permissions = localStorage.getItem('auth')
  return <CardActions>{permissions !== 'manager' ? <CreateButton basePath={basePath} /> : null}</CardActions>
}
const ListFilter = (props) => (
  <Filter {...props}>
    <SearchInput label={props.translate('resources.banners.fields.name')} source="search" alwaysOn />
  </Filter>
)
const ThumbnailImageField = withStyles({image: {height: 60}})(ImageField)

export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props} actions={<Actions />} perPage={25} filters={<ListFilter />} bulkActions={false}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <ThumbnailImageField source="imagePath" sortable={false} />
        <TextField source="name" />
        <SelectField
          source="type"
          choices={[
            {id: 'events', name: '이벤트'},
            {id: 'notice', name: '공지사항'},
            {id: 'exhibition', name: '기획전'},
            {id: 'community', name: '커뮤니티 상세페이지'},
            {id: 'url', name: '링크'},
            {id: 'image', name: '이미지'},
            {id: 'walk', name: '산책'},
            {id: 'myPage', name: '마이 페이지'},
            {id: 'seedCampaignMain', name: '체험단 메인'},
            {id: 'postingCommunity', name: '커뮤니티 게시글 상세'},
            {id: 'postingNotice', name: '공지사항 게시글 상세'},
            {id: 'postingEvent', name: '이벤트 게시글 상세'},
            {id: 'shoppingExhibition', name: '쇼핑 기획전'}
          ]}
        />

        <SelectField
          source="target"
          choices={[
            {id: 'landing', name: '❌온 보딩❌'},
            {id: 'talk', name: '❌게시판 탭❌'},
            {id: 'community', name: '❌커뮤니티 배너❌'},
            {id: 'product', name: '❌쇼핑 > 서브배너❌'}, //쇼핑 중간 미디움 배너
            {id: 'product1', name: '❌쇼핑 > 메인배너❌'}, //쇼핑 상단 라지 배너
            {id: 'walk', name: '❌산책 띠배너❌'},
            {id: 'home', name: '홈 > 중간배너'},
            {id: 'communityList', name: '커뮤니티 리스트'},
            {id: 'communityDetail', name: '커뮤니티 상세'},
            {id: 'shoppingMain', name: '쇼핑 > 상단 메인배너'},
            {id: 'shoppingMiddle', name: '쇼핑 > 중간 서브배너'},
            {id: 'seedCampaign', name: '체험단'},
            {id: 'myPage', name: '마이 페이지'},
            {id: 'walkReady', name: '산책 시작 전'},
            {id: 'walkCalendar', name: '산책 일지'},
            {id: 'onBoarding', name: '앱 시작 화면'}
          ]}
        />

        <TextField source="content" />
        <TextField source="order" />
        <BooleanField source="onOff" />
        <TextField source="userClick" />
        <DateField source="startDate" locales="ko" showDate sortable={false} />
        <DateField source="endDate" locales="ko" showDate sortable={false} />
        <DateField source="createdAt" />
      </Datagrid>
    </List>
  )
})
