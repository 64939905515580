import {
  CREATE,
  DELETE,
} from 'react-admin'

export function request(type, params) {
  let url = '/admin/auth'
  let options = {}

  switch (type) {
    case 'LOGIN':
      localStorage.setItem('userName', params.data.userName)

      options.method = 'POST'
      params.data.name = params.data.userName
      delete params.data.userName
      options.data = params.data
      break

    case 'REFRESH':
      url += '/refresh'
      options.method = 'POST'
      options.data = params.data
      break

    case CREATE:
      url += '/register'
      options.method = 'POST'
      options.data = params.data
      break

    case DELETE:
      options.method = 'DELETE'
      break
    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res

  switch (type) {
    default:
      if (data) ret = {data}
      break
  }
  return ret
}
