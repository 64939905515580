import {CREATE, DELETE, GET_LIST, GET_MANY, GET_ONE, UPDATE} from 'react-admin'
import {convertImagePath, uploadToS3} from './ImageHandler'
import {ImageParse, ImageParseUpdate} from './ImageMethod'

export async function request(type, params) {
  let url = '/admin/places'
  const options = {}
  switch (type) {
    case UPDATE:
    case CREATE:
      if (type === UPDATE) {
        url += `/${params.id}`
        options.method = 'PUT'
      } else options.method = 'POST'
      options.data = params.data

      const data = {...params.data}
      if (options.data.titleImagePath.image) {
        if (options.data.titleImagePath.image.substring(0, 5) === 'https') {
          options.data.titleImagePath.image = ImageParse(options.data.titleImagePath.image, 'places')
        }
      }

      options.data = await uploadToS3(data, 'titleImagePath', 'places', 'titleImagePath')
      options.data = await uploadToS3(data, 'images', 'places', 'images')
      if (options.data.weekdayBusinessHourStart && options.data.weekdayBusinessHourStart.length < 6)
        options.data.weekdayBusinessHourStart += ':00'
      if (options.data.weekdayBusinessHourEnd && options.data.weekdayBusinessHourEnd.length < 6)
        options.data.weekdayBusinessHourEnd += ':00'
      if (options.data.weekendBusinessHourStart && options.data.weekendBusinessHourStart.length < 6)
        options.data.weekendBusinessHourStart += ':00'
      if (options.data.weekendBusinessHourEnd && options.data.weekendBusinessHourEnd.length < 6)
        options.data.weekendBusinessHourEnd += ':00'

      const src = options.data.titleImagePath.image
      options.data.titleImagePath = src

      if (options.data.dfangName === '') options.data.dfangName = undefined
      if (options.data.dfangName === null) options.data.dfangName = undefined
      if (options.data.kakaotalk === null) options.data.kakaotalk = undefined
      if (options.data.kakaotalk === '') options.data.kakaotalk = undefined
      if (options.data.facebook === null) options.data.facebook = undefined
      if (options.data.facebook === '') options.data.facebook = undefined
      if (options.data.blog === null) options.data.blog = undefined
      if (options.data.blog === '') options.data.blog = undefined
      if (options.data.homepage === null) options.data.homepage = undefined
      if (options.data.homepage === '') options.data.homepage = undefined
      if (options.data.instagram === null) options.data.instagram = undefined
      if (options.data.instagram === '') options.data.instagram = undefined
      if (options.data.weekdayBusinessHourStart === null) options.data.weekdayBusinessHourStart = undefined
      if (options.data.weekdayBusinessHourEnd === null) options.data.weekdayBusinessHourEnd = undefined
      if (options.data.weekendBusinessHourStart === null) options.data.weekendBusinessHourStart = undefined
      if (options.data.weekendBusinessHourEnd === null) options.data.weekendBusinessHourEnd = undefined

      if (!options.data.breedIds) options.data.breedIds = []
      if (!options.data.facilityIds) options.data.facilityIds = []
      if (!options.data.serviceIds) options.data.serviceIds = []

      if (options.data.titleImagePath.substring(0, 5) === 'https') {
        options.data.titleImagePath = ImageParseUpdate(options.data.titleImagePath, 'places')
      }

      options.data.images.forEach((v) => {
        if (v.thumbnail.substring(0, 5) === 'https') v.thumbnail = ImageParse(v.thumbnail, 'places')
        if (v.image.substring(0, 5) === 'https') v.image = ImageParse(v.image, 'places')
      })
      break

    case GET_LIST:
      const {page, perPage} = params.pagination
      const {field, order} = params.sort
      options.params = {
        ...params.filter,
        sort: field,
        order,
        start: (page - 1) * perPage,
        perPage
      }
      break

    case GET_MANY:
      if (params.ids.length === 1) url += `/${params.ids[0]}/info`
      break

    case GET_ONE:
      url += `/${params.id}`
      break

    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break

    case 'EXCEL':
      options.params = {
        start: 0,
        perPage: 1000
      }
      break

    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res
  switch (type) {
    case UPDATE:
      ret = params
      break
    case GET_MANY:
      if (params.ids.length === 1) ret = {data: [data]}
      else ret = {data}
      break
    case GET_ONE:
      data.titleImagePath = convertImagePath(data.titleImagePath, 'large')
      data.images = data.images.map((image) => {
        image.thumbnail = convertImagePath(image.thumbnail, 'thumbnail')
        image.image = convertImagePath(image.image, 'large')
        return image
      })
      if (typeof data.titleImagePath === 'string') {
        const image = data.titleImagePath
        data.titleImagePath = {}
        data.titleImagePath.image = image
      }
      if (data.weekdayBusinessHourStart) data.weekdayBusinessHourStart = data.weekdayBusinessHourStart.substring(0, 5)
      if (data.weekdayBusinessHourEnd) data.weekdayBusinessHourEnd = data.weekdayBusinessHourEnd.substring(0, 5)
      if (data.weekendBusinessHourStart) data.weekendBusinessHourStart = data.weekendBusinessHourStart.substring(0, 5)
      if (data.weekendBusinessHourEnd) data.weekendBusinessHourEnd = data.weekendBusinessHourEnd.substring(0, 5)

      ret = {data}
      break
    case GET_LIST:
      data.data.forEach((row) => (row.titleImagePath = convertImagePath(row.titleImagePath, 'large')))

      ret = data
      break
    case DELETE:
      ret = {data: params}
      break
    default:
      if (data) ret = {data}
      break
  }
  return ret
}
