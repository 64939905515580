import {CREATE, DELETE, GET_LIST, GET_MANY, GET_ONE, UPDATE} from 'react-admin'
import {convertImagePath} from './ImageHandler'

export async function request(type, params) {
  let url = '/admin/productReviews'
  const options = {}

  switch (type) {
    case UPDATE:
    case CREATE:
      if (type === UPDATE) {
        url += `/${params.id}`
        options.method = 'PUT'
      } else options.method = 'POST'
      options.data = params.data
      break

    case GET_LIST:
      const {page, perPage} = params.pagination
      const {field, order} = params.sort
      options.params = {
        ...params.filter,
        sort: field,
        order,
        start: (page - 1) * perPage,
        perPage
      }
      break

    case GET_MANY:
      url += `/${params.ids[0]}`
      break

    case GET_ONE:
      url += `/${params.id}`
      break

    case DELETE:
      url += `/${params.id}/orderReview`
      options.method = 'DELETE'
      break

    case 'EXCEL':
      options.params = {
        start: 0,
        perPage: 100 //@@ excel download identifier
      }
      break

    default:
      break
  }

  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res

  switch (type) {
    case UPDATE:
      ret = params
      break

    case GET_MANY:
      if (params.ids.length === 1) ret = {data: [data.data]}
      else ret = {data}
      break

    case GET_ONE:
      if (data.data.images.length <= 0) {
        ret = data
        break
      }

      const fullImage = convertImagePath(data.data.images[0].image, 'large')

      data.data.images = data.data.images.map((value) => {
        return {image: convertImagePath(value.image, 'large')}
      })

      if (typeof fullImage === 'string') {
        const image = data.thumbnailPath
        data.thumbnailPath = {}
        data.thumbnailPath.image = image
      }
      ret = data
      break

    case GET_LIST:
      const rows = data.data

      for (const row of rows) {
        if (row.images[0]) row.thumbnail = convertImagePath(row.images[0].thumbnail, 'thumbnail')
      }
      ret = data
      break

    case DELETE:
      ret = {data: params}
      break

    case 'EXCEL':
      ret = data
      break

    default:
      if (data) ret = {data}
      break
  }

  return ret
}
