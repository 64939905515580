import React from 'react'
import {
  BooleanInput,
  DateTimeInput,
  Edit,
  FormDataConsumer,
  ImageField,
  ImageInput,
  LongTextInput,
  maxLength,
  minLength,
  NumberInput,
  ReferenceInput,
  regex,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput
} from 'react-admin'
import {required} from '../../validators'
import EditActions from '../../custom/common/EditActions'
import EditToolbar from '../../custom/common/EditToolbar'
import {Typography} from '@material-ui/core'

export default (props) => {
  return (
    <Edit {...props} actions={<EditActions />}>
      <SimpleForm toolbar={<EditToolbar />}>
        <TextInput source="name" validate={[required, maxLength(100), minLength(1)]} />
        <ImageInput source="imagePath" accept="image/*" validate={[required]}>
          <ImageField source="image" title="title" />
        </ImageInput>
        {/*랜딩 페이지*/}
        <SelectInput
          source="type"
          choices={[
            {id: 'events', name: '이벤트'},
            {id: 'notice', name: '공지사항'},
            {id: 'exhibition', name: '기획전'},
            {id: 'community', name: '커뮤니티 상세페이지'},
            {id: 'url', name: '링크'},
            {id: 'image', name: '이미지'},
            {id: 'walk', name: '산책'},
            {id: 'myPage', name: '마이 페이지'},
            {id: 'seedCampaignMain', name: '체험단 메인'},
            {id: 'postingCommunity', name: '커뮤니티 게시글 상세'},
            {id: 'postingNotice', name: '공지사항 게시글 상세'},
            {id: 'postingEvent', name: '이벤트 게시글 상세'},
            {id: 'shoppingExhibition', name: '쇼핑 기획전'},
            {id: 'gmarket', name: 'G마켓'}
          ]}
          validate={[required]}
        />

        <FormDataConsumer>
          {({formData, ...rest}) => {
            return (
              <>
                {formData && formData.type === 'exhibition' ? (
                  <ReferenceInput
                    label="기획전"
                    source="content"
                    reference="exhibition"
                    validate={required}
                    perPage={200}
                  >
                    <SelectInput optionText="title" />
                  </ReferenceInput>
                ) : formData && formData.type === 'notice' ? (
                  <ReferenceInput
                    label="공지사항"
                    source="content"
                    reference="notices"
                    validate={required}
                    perPage={200}
                  >
                    <SelectInput optionText="title" />
                  </ReferenceInput>
                ) : formData && formData.type === 'events' ? (
                  <ReferenceInput label="이벤트" source="content" reference="events" validate={required} perPage={200}>
                    <SelectInput optionText="title" />
                  </ReferenceInput>
                ) : formData && formData.type === 'gmarket' ? (
                  <div>
                    <p>현재 G마켓 링크는 고정 적용해놨습니다. 변경을 원하시면 개발팀에 문의부탁드립니다</p>
                  </div>
                ) : (
                  <LongTextInput
                    label="추가적인 내용을 입력해주세요."
                    source="content"
                    validate={[required, minLength(1)]}
                  />
                )}

                {formData && formData.type === 'url' ? (
                  <TextInput
                    label="광고 파라미터"
                    source="adUrl"
                    validate={[maxLength(20), regex(/^[a-zA-Z]*$/, '영문자만 가능합니다.')]}
                  />
                ) : (
                  <div></div>
                )}
                <div>
                  <SelectInput
                    source="target"
                    label="배너위치"
                    choices={[
                      {id: 'landing', name: '❌온 보딩❌'},
                      {id: 'talk', name: '❌게시판 탭❌'},
                      {id: 'community', name: '❌커뮤니티 배너❌'},
                      {id: 'product', name: '❌쇼핑 > 서브배너❌'}, //쇼핑 중간 미디움 배너
                      {id: 'product1', name: '❌쇼핑 > 메인배너❌'}, //쇼핑 상단 라지 배너
                      {id: 'walk', name: '❌산책 띠배너❌'},
                      {id: 'home', name: '홈 > 중간배너'},
                      {id: 'communityList', name: '커뮤니티 리스트'},
                      {id: 'communityDetail', name: '커뮤니티 상세'},
                      {id: 'shoppingMain', name: '쇼핑 > 상단 메인배너'},
                      {id: 'shoppingMiddle', name: '쇼핑 > 중간 서브배너'},
                      {id: 'seedCampaign', name: '체험단'},
                      {id: 'myPage', name: '마이 페이지'},
                      {id: 'walkReady', name: '산책 시작 전'},
                      {id: 'walkCalendar', name: '산책 일지'},
                      {id: 'onBoarding', name: '앱 시작 화면'}
                    ]}
                    validate={[required]}
                  />
                </div>
                {formData && formData.target === 'communityList' ? (
                  <div>
                    <TextInput
                      label="배너 위치"
                      source="position"
                      validate={[required, regex(/^[0-9]+$/, '숫자만 입력 가능합니다')]}
                    />
                    <Typography variant="body2">
                      * 커뮤니티 탭 클릭시 표출되는 리스트에서 배너가 몇 번째 표출을 원하시는지 작성해주세요. (숫자만
                      입력 가능합니다)
                    </Typography>
                  </div>
                ) : (
                  <TextField label="" /> // Empty TextField with no label
                )}
              </>
            )
          }}
        </FormDataConsumer>
        <NumberInput source="order" validate={required} />
        <BooleanInput source="onOff" validate={[required]} defaultValue={false} />
        <BooleanInput source="isAd" validate={[required]} defaultValue={false} />
        <BooleanInput source="isOfferwall" validate={[required]} defaultValue={false} />
        <FormDataConsumer>
          {({formData, ...rest}) => {
            if (formData.isOfferwall) {
              return (
                <>
                  <NumberInput label="지급 포인트" source="rewardPoint" defaultValue={0} validate={required} />
                  <Typography variant="body2">
                    * 같은 배너를 여러개 생성하셨다면 현재 시스템상 중복 적용이 안되어 수동으로 생성한 모든 동일 배너에
                    포인트 적용을 해주셔야됩니다.
                  </Typography>
                </>
              )
            }
          }}
        </FormDataConsumer>

        <DateTimeInput source="startDate" locales="ko" validate={[required]} defaultValue={new Date()} />
        <DateTimeInput source="endDate" locales="ko" validate={[required]} defaultValue={new Date()} />
      </SimpleForm>
    </Edit>
  )
}
