import React, {useState, useEffect} from "react";

const TableDay = () => {
  const day = Array.from({length: 31}, (_, i) => i)
  return (
    <>
      <tr>
        <td colSpan={2}/>
        {day.map((i) => <td key={i} style={{fontSize: '10px'}}>D-{i}</td>)}
      </tr>
    </>
  )
}

export default ({data, type}) => {
  const [handleData, setHandleData] = useState(null)
  useEffect(() => {
    let tempData = [[], [], [], [], []]
    if (data) {
      data.data.forEach((day) => {
        for(let i = 0 ; i < 5 ; i++){
          if(day.products[i]) {
            type === '가격'
              ? tempData[i].push(`${day.products[i].name}(${day.products[i].price})`)
              : tempData[i].push(`${day.products[i].name}(${day.products[i].count})`)
          }else{
            tempData[i].push(`N/A`)
          }
        }
      })
      setHandleData(tempData)
    }
  }, [data, type])
  const TableTr = (props) => {
    return (
      <tr>
        {props.components}
        <td>
          #{Number(props.id) + 1}
        </td>
        {handleData && handleData[props.id].map((text, id) => {
          return <td key={text + id} style={{fontSize: '12px'}}>{text}</td>
        })
        }
      </tr>
    )
  }

  return (<>
    <table style={{width:'2500px', height:'375px'}} className='dashboard-table' key={type} border="1" cellSpacing="0">
      <thead>
      <TableDay/>
      </thead>
      <tbody>
      <TableTr id={0} components={<td rowSpan={6} >
        Top5 판매상품 <br/> ({type})
      </td>}/>
      <TableTr id={1}/>
      <TableTr id={2}/>
      <TableTr id={3}/>
      <TableTr id={4}/>
      </tbody>
    </table>
  </>)
}
